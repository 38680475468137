.marine-watch-wrapper {
  width: 100%;
  height: 100%;
  .leaflet-container {
    height: 100%;
  }
}
.custom-arrow-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.custom-arrow-marker .arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 15px solid red; /* Default color, can be overridden by style */
}
.custom-arrow-marker .circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
@keyframes dash {
  to {
    stroke-dashoffset: -50%;
  }
}

.dash-dot-line {
  stroke: red;
  stroke-width: 3;
  stroke-dasharray: 10, 5; /* dash-dot pattern */
  animation: dash 20s linear infinite;
}
