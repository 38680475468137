// @import "./../../styles/colors";

.dropdown-menu-root {
  padding: 6px 8px;
  border: none;
  font-size: 16px;
  background: none;
  border-radius: 4px;
}

.dropdown-menu-root[data-open],
.dropdown-menu-root:hover {
  background: var(--chakra-colors-primary-500);
}

.dropdown-menu-main {
  background: rgba(255, 255, 255);
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  outline: 0;
  border: 1px solid #d7dce5;
}

.dropdown-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  line-height: 1.8;
  min-width: 110px;
  margin: 0;
  outline: 0;
  padding-left: 8px;
}

.dropdown-menu-item:focus {
  background: var(--chakra-colors-primary-300);
  color: white;
}

.dropdown-menu-item[data-nested][data-open]:not([data-focus-inside]) {
  background: var(--chakra-colors-primary-400);
  color: white;
}

.dropdown-menu-item[data-focus-inside][data-open] {
  background: var(--chakra-colors-primary-500);
}
