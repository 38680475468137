.task-img-modal {
  display: flex;
  // flex-wrap: wrap;
  // gap: 12px;
  // justify-content: center;
  // max-height: 600px;
  overflow: hidden;
  .info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 12px;
  }
  .data-info {
    display: flex;
    gap: 12px;
    & > :first-child {
      font-weight: 600;
    }
    & > :last-child {
    }
  }
}

.task-report-modal {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  max-height: 600px;
  overflow: auto;
  background: #fff;

  img {
    width: 100%;
    margin: 20px auto;
  }
  table {
    width: fit-content;
    min-width: 300px;
    max-width: 100%;
    border-collapse: collapse;
    border: 1px solid #666;
    color: #333;
    text-align: left;
    td,
    th {
      border: 1px solid #666;
      padding: 4px;
    }
    thead {
      background: #ddd;
    }
  }
}
#report-map-wrapper .leaflet-container {
  height: 400px;
}
