.chatbot-button {
  background: #192f81;
  color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-top: 10px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #516acc;
  }
}

.chatbot-holder {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 123px);
  width: 100%;

  .rsc-header {
    background: #192f81;
  }

  .rsc-ts-bubble {
    background: #516acc;
    color: white;
  }
}

/* Chatbot.css */
.chatbot {
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.15) 0 12px 24px 0;
  background: rgb(245, 248, 251);
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease 0s;
  transform-origin: right bottom;
  display: flex;
  flex-direction: column;
}

.chatbot-header {
  background: #192f81;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  color: #fff;
  padding: 16px;
  font-size: 20px;
  display: flex;

  span {
    margin-left: auto;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

.messages {
  height: calc(100vh - 172px);
  overflow-y: auto;
  padding: 2px;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.message {
  max-width: 70%;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  word-wrap: break-word;
  color: #333;
}

.message.user {
  background-color: #daf1da;
  align-self: flex-end;
  border-bottom-right-radius: 0;
  margin-left: auto;
  width: fit-content;
}

.message.bot {
  background-color: #f1f1f1;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  width: fit-content;
}

.chat-input {
  display: flex;
  // margin-top: 10px;
  padding: 10px;
}

.chat-input input {
  flex-grow: 1;
  padding: 5px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  margin-right: 8px;
  outline: none;

  &:focus {
    border: none;
    outline: none;
  }
}

.chat-input span {
  color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chat-loading {
  background: #f1f1f1;
  border-radius: 16px;
  padding: 5px;
  text-align: center;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.chat-bot-button-primary {
  background: #4caf50;
  color: #fff;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.chat-bot-button-secondary {
  background: #fff;
  color: #111;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.img {
  object-fit: cover;
  width: 300px;
  height: 150px;
  margin: 10px;
}

.chat-bot-calendar-wrapper {
  border: 1px solid #eee;
  padding: 5px 20px 20px 20px;
  border-radius: 12px;
  width: 500px;
  box-shadow: 3px 3px 3px #f7f7f7;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chat-bot-collection-wrapper {
  border: 1px solid #eee;
  padding: 5px 20px 20px 20px;
  border-radius: 12px;
  width: 500px;
  box-shadow: 3px 3px 3px #f7f7f7;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}

.chat-bot-collection-error {
  color: rgb(172, 46, 46);
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
}

.chat-bot-exit-wrapper {
  border: 1px solid #eee;
  padding: 5px 20px 20px 20px;
  border-radius: 12px;
  width: 400px;
  box-shadow: 3px 3px 3px #f7f7f7;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}
.message-group {
  background: #fafafa;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  &__header {
    cursor: pointer;
    color: #666;
    background: #f1f1f1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-top: 2px;
    &.collapsed {
      background: #e6e6e6;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px;
    table {
      width: 400px;
      border-collapse: collapse;
      border: 1px solid #666;
      color: #333;
      text-align: left;
      td,
      th {
        border: 1px solid #666;
        padding: 4px;
      }
      thead {
        background: #ddd;
      }
    }
  }
}
.message-buttonbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
}
.message-button {
  cursor: pointer;
  background: #fff;
  border: 1px solid #666;
  height: 24px;
  border-radius: 12px;
  padding: 0 12px;
  margin-left: 8px;
  &:hover {
    color: #fff;
    background: #516acc;
  }
}
.chatbot-tasks {
  display: flex;
  background-color: #fff;
  padding: 8px;
  border-top: 1px solid #ddd;
}
.chatbot-current-info {
  display: flex;
  background-color: #fff;
  padding: 8px;
}
