@import '../variables.module.scss';

.language-selector {
  .menu-globe {
    color: $whiteColor;
    border-radius: 0;
    width: 45px;
    margin: 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
  }

  .menu-flags {
    color: $whiteColor;
    padding: 0;

    button {
      padding: 0;
      color: $textColor;
      text-transform: uppercase;
      border: none;

      span {
        font-size: 13px;
        font-weight: $fontWeightBold;
        margin: 0;
        padding: 0;
      }
    }

    ul {
      max-height: none;
      color: $textColor;
      background-color: $mainLight;
      padding: 0;
      margin-top: 1.4px;
      border: none;
      border-radius: 0;
      text-transform: capitalize;
      box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.1);

      li {
        font-size: 13px;
      }

      li:hover {
        background-color: $primaryColor;
        color: $whiteColor;
      }

      @media only screen and (max-height: 450px) {
        & {
          max-height: 200px;
        }
      }
    }

    .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded='false'] { /* stylelint-disable-line */
      border-radius: 0;
      width: 45px;
      margin: 0;
      padding: 5px 0;
      display: flex;
      justify-content: center;

      &::after {
        content: url(./icons/chevron-down.svg);
        display: flex;
        align-items: center;
        border: none;
        width: unset;
        margin-left: 7px;
        font-size: 0;
        transform: scale(0.8);
      }

      span {
        color: $whiteColor;
      }
    }

    .ReactFlagsSelect-module_selectFlag__2q5gC { /* stylelint-disable-line */
      display: none;
    }

    .ReactFlagsSelect-module_selectOptionValue__vS99- { /* stylelint-disable-line */
      .ReactFlagsSelect-module_selectFlag__2q5gC { /* stylelint-disable-line */
        display: inline-flex;
      }
    }

    .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded='true'] { /* stylelint-disable-line */
      background-color: $mainLight;
      color: $textColor;
      border-radius: 0;
      position: relative;
      z-index: 2;
      width: 45px;
      margin: 0;
      padding: 5px 0;
      display: flex;
      justify-content: center;

      &::after {
        content: url(../icons/chevron-up.svg);
        display: flex;
        align-items: center;
        border: none;
        width: unset;
        margin-left: 7px;
        font-size: 0;
        transform: scale(0.8);
      }

      span {
        color: $textColor;
      }
    }

    .ReactFlagsSelect-module_selectOption__3pcgW { /* stylelint-disable-line */
      margin: 2px;
    }
  }

  .ReactFlagsSelect-module_flagsSelect__2pfa2 { /* stylelint-disable-line */
    ul {
      #rfs-TO::before { /* stylelint-disable-line */
        content: '';
        position: absolute;
        border-top: 1px solid #dbdfe3;
        padding-right: 92px;
        margin-left: -6px;
      }

      #rfs-TO { /* stylelint-disable-line */
        span {
          margin-top: 4px;

          span::before {
            content: url(../icons/people-group.svg);
            margin-right: 10px;
          }

          span:nth-child(1) {
            display: none;
          }

          span:nth-child(2) {
            color: $primaryColor;
            width: 100%;
            padding: 0;
            text-decoration: underline;
            text-underline-offset: 2px;
            overflow: visible;
            text-overflow: unset;
          }
        }

        &:hover {
          background-color: $whiteBackground;
        }
      }
    }
  }

  .menu-flags::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    background: $whiteColor;
    height: 1px;
    border-radius: 10px 10px 10px 10px;
  }
}
